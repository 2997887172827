<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="goHerf">测评列表</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a"
          >结果设置</a>
        </span>
      </div>
      <div class="framePage-body">
        <el-row>
          <el-form
              :model="topForm"
              :rules="rules"
              ref="topForm"
              label-width="8rem"
              class="demo-ruleForm"
              style="width: 100%;"
              label-position="top"

          >
            <el-form-item label="结果展示内容" prop="editorTopContent">
              <div ref="editorTop" class="editor" style="width:95%;max-width:1500px;margin: 0 0.675rem" />
              <span v-show="descriptionTopTitle" class="showtitle"
              >请输入结果展示内容</span
              >
            </el-form-item>
            <el-form-item label="展示详情" style="position:relative;">
              <el-button style="position:absolute;top: -46px;left: 75px;" class="bgc-bv" size="small" @click="add()" v-if="!published">新增</el-button>
              <div class="framePage-scroll">
                <div class="ovy-a">
                  <el-table
                      ref="tableData"
                      :data="tableData"
                      size="small"
                      tooltip-effect="dark"
                      style="width: 100%"
                      stripe
                      :header-cell-style="tableHeader"
                  >
                    <el-table-column
                        label="序号"
                        align="center"
                        type="index"
                        :index="indexMethod"
                    />
                    <el-table-column
                        label="分类名称"
                        align="center"
                        show-overflow-tooltip
                        prop="componentName"
                    />
                    <el-table-column
                        label="分类详情"
                        align="center"
                        show-overflow-tooltip
                        prop="componentDesc"
                    >
                      <template slot-scope="scope">
                        <span v-if="scope.row.componentDesc">{{scope.row.componentDesc}}</span>
                        <span v-else>--</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center" width="300px">
                      <div slot-scope="scope" class="flexcc">
                        <el-button
                            style="padding: 0px 5px"
                            type="text"
                            size="mini"
                            @click="onEditor(scope.row)"
                        >{{published?'查看':'编辑'}}</el-button
                        >
                        <el-button
                            style="padding: 0px 5px"
                            type="text"
                            size="mini"
                            @click="selectSubject(scope.row)"
                        >选择/查看题目</el-button
                        >
                        <el-button
                            style="padding: 0px 5px"
                            type="text"
                            size="mini"
                            @click="setScore(scope.row)"
                        >设置分值</el-button
                        >
                        <el-button
                            style="padding: 0px 5px"
                            type="text"
                            size="mini"
                            @click="onDelete(scope.row)"
                            :disabled="published"
                        >删除</el-button
                        >

                      </div>
                    </el-table-column>
                    <Empty slot="empty" />
                  </el-table>
                </div>
              </div>
              <div class="btn-box flexcc" style="width:100%;padding: 16px 0;" v-if="!published">
                <el-button @click="goHerf" class="bgc-bv">取 消</el-button>
                <el-button class="bgc-bv" @click="doEditSave">确 定</el-button>
              </div>
            </el-form-item>
          </el-form>
        </el-row>

      </div>

    </div>
    <!-- 新增/编辑  弹窗 -->
    <el-dialog
        :title="dialogTitle"
        :visible.sync="centerDialogVisible"
        width="80%"
        center
        class="relation_jg_dialog"
        :close-on-click-modal="false"
        @close="doClose"
    >
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="8rem"
          class="demo-ruleForm"
          style="width: 90%"
      >
        <el-form-item label="分类名称：" prop="componentName">
          <el-input
              v-model="ruleForm.componentName"
              size="small"
              placeholder="请输入分类名称"
              maxlength="50"
              show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="分类详情：" prop="evaluationDetails" class="form-item" style="overflow: hidden;">
          <el-input
              type="textarea"
              :rows="8"
              size="small"
              placeholder="请输入分类详情"
              v-model="ruleForm.evaluationDetails"
              maxlength="2000"
              show-word-limit
              resize="none">
          </el-input>
          <div ref="editor" class="editor" style="width:100%;max-width:1500px" />
        </el-form-item>

      </el-form>
      <div style="display: flex; justify-content: center" v-if="!published">
        <el-button @click="doClose()">取消</el-button>
        <el-button class="bgc-bv" @click="submitForm('ruleForm')">
          确定
        </el-button>
      </div>
    </el-dialog>

    <!--查看选择题目-->
    <el-dialog
        title="选择题目"
        :visible.sync="dialogVisible"
        width="80%"
        center
        class="relation_jg_dialog"
        :close-on-click-modal="false"
        @close="doCloseSubject"
    >
      <div class="framePage-scroll">
        <div class="ovy-a">
          <el-table
              ref="multipleTable"
              :data="tableData2"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
              row-key="questionId"
              @selection-change="handleSelectionChange">

            <el-table-column
                type="selection"
                width="55"
                :reserve-selection="true"/>
            <el-table-column label="序号" align="center" type="index" :index="indexMethod" width="50px"/>
            <el-table-column
                label="题目名称"
                prop="questionName"
                show-overflow-tooltip
                min-width="160"
            >
              <template slot-scope="scope">
                <span v-html="scope.row.questionName"></span>
              </template>
            </el-table-column>
            <el-table-column
                label="题目类型"
                show-overflow-tooltip
                prop="questionType"
                min-width="160"
            >
              <template slot-scope="scope">
                {{$setDictionary("HR_QUESTION_TYPE", scope.row.questionType) }}
              </template>
            </el-table-column>
            <Empty slot="empty" />
          </el-table>
        </div>
      </div>
      <div style="display: flex; justify-content: center;margin-top: 16px;" v-if="!published">
        <el-button @click="doCloseSubject">取消</el-button>
        <el-button class="bgc-bv" @click="submitSubjectForm">
          确定
        </el-button>
      </div>
    </el-dialog>


    <!--设置分值-->
    <el-dialog
        title="设置分值"
        :visible.sync="scoreDialogVisible"
        width="80%"
        center
        class="relation_jg_dialog"
        :close-on-click-modal="false"
        @close="doCloseScore"
    >
      <div>
        <el-row v-for="(item,index) in scoreForms" :key="index">
            <el-col :span="24" style="margin-bottom: 16px;font-weight: bold;">
              第{{index+1}}组
            </el-col>
            <el-col :span="24" style="margin-bottom: 16px;">
              <el-col :span="4">
                <span class="labelTitle"><i class="redColor">*&nbsp;</i>分值</span>
              </el-col>
              <el-col :span="10">
                <el-input-number v-model="item.minScore" size="small" :controls="false" :min="1" :max="999999" :step="1" :precision="0"></el-input-number>
                <span class="labelTitle">&nbsp;--&nbsp;</span>
                <el-input-number v-model="item.maxScore" size="small" :controls="false" :min="1" :max="999999" :step="1" :precision="0"></el-input-number>
              </el-col>
              <el-col :span="8">
                <el-button class="bgc-bv" size="small" @click="scoreAdd">增加</el-button>
                <el-button size="small" type="danger" @click="scoreDel(index)" v-if="scoreForms.length > 1">删除</el-button>
              </el-col>
            </el-col>
            <el-col :span="24">
              <span class="labelTitle"><i class="redColor">*&nbsp;</i>分值内容</span>
            </el-col>
            <el-col :span="24" style="margin-bottom: 16px;">
              <el-input
                  type="textarea"
                  :rows="8"
                  size="small"
                  placeholder="请输入内容"
                  v-model="item.evaluation"
                  maxlength="2000"
                  show-word-limit
                  resize="none">
              </el-input>
            </el-col>
            <el-col :span="24">
              <span class="labelTitle"><i class="redColor">*&nbsp;</i>提升建议</span>
            </el-col>
            <el-col :span="24" style="margin-bottom: 16px;">
              <el-input
                  type="textarea"
                  :rows="8"
                  size="small"
                  placeholder="请输入内容"
                  v-model="item.proposal"
                  maxlength="2000"
                  show-word-limit
                  resize="none">
              </el-input>
            </el-col>
            <el-col :span="24">
              <span class="labelTitle">匹配职位类别</span>
            </el-col>
            <el-col :span="24">
              <el-cascader
                  v-model="item.matchingPosition"
                  size="small"
                  :options="options"
                  :props="props"
                  :show-all-levels="false"
                  @change="getCheckedNodes"
                  clearable>

              </el-cascader>
            </el-col>
            <el-col :span="24" v-if="scoreForms.length > 1 && index != scoreForms.length - 1">
              <el-divider></el-divider>
            </el-col>
        </el-row>
      </div>
      <div style="display: flex; justify-content: center;margin-top: 16px;" v-if="!published">
        <el-button @click="doCloseScore">取消</el-button>
        <el-button class="bgc-bv" @click="submitScoreForm">
          确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import List from "@/mixins/List";
import E from "wangeditor";
import { resetKeepAlive,validateURL } from "@/utils/common";

let editorTop = {};
let editor = {};
export default {
  name: "structureSectting",
  components: {
    Empty,
  },
  mixins: [List],
  data() {
    var Phone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入联系电话"));
      } else if (
          value &&
          !/^1[3456789]\d{9}$/.test(value) &&
          !/^([0-9]{3,4}-)?[0-9]{7,8}$/.test(value)
      ) {
        callback(new Error("请输入正确的联系电话"));
      } else {
        callback();
      }
    };
    return {
      componentName: "", //人员姓名

      topForm: {
        editorTopContent: '',
      },
      descriptionTopTitle: false,


      centerDialogVisible: false,
      dialogTitle: "", // 弹框的标题
      ruleForm: {
        componentId: "", // 分类ID
        componentName: "", // 分类名称
        evaluationDetails: '',// 分类详情
      },
      rules: {
        componentName: [{ required: true, message: "请输入测试问卷标题", trigger: "blur" }],
      },



      tableData2: [],
      dialogVisible: false,
      multipleSelection: [],
      currentComponentId: '',

      scoreDialogVisible: false,
      scoreForms: [
        {
          minScore: undefined,
          maxScore: undefined,
          evaluation: '',
          proposal: '',
          matchingPosition: [],
        }
      ],
      // 职位类别-下拉列表
      options: [],
      props:{
        multiple: true,
        checkStrictly: true,
        value: 'positionTypeId',
        label: 'positionTypeName',
      },

      paperId: '',
      published: false,
    };
  },
  computed: {},
  mounted() {
    if(this.$route.query.row&&this.$route.query.row.paperId){
      this.paperId = this.$route.query.row.paperId
      localStorage.setItem('CPpaperId', this.$route.query.row.paperId);
    }else if(localStorage.getItem('CPpaperId')){
      this.paperId = localStorage.getItem('CPpaperId')
    }

    if(this.$route.query.row&&(this.$route.query.row.published == true||this.$route.query.row.published == false)){
      this.published = this.$route.query.row.published
      localStorage.setItem('CPpublished', this.$route.query.row.published);
    }else if(localStorage.getItem('CPpublished')){
      this.published = localStorage.getItem('CPpublished') == 'false'?false:localStorage.getItem('CPpublished') == 'true'?true:false
    }
    this.editorInitTop()
    this.getHrPositionType()
  },
  created() {
  },
  watch: {
    // $route: {
    //   handler: function (val, oldVal) {
    //     if (val.query.refresh) {
    //       this.getData(-1);
    //     }
    //   },
    //   // 深度观察监听
    //   deep: true,
    // },
  },
  methods: {
    // 页面底部确定按钮
    doEditSave(){
      let str = this.topForm.editorTopContent.replace(/<[^>]+>/g, "");
      str.replace(/\s*/g, "");
      console.log(str);
      if (!str) {
        this.descriptionTopTitle = true
        return false;
      }else{
        this.descriptionTopTitle = false
        if(editorTop.txt.html().length>2000){
          this.$message({
            type: "error",
            message: "结果展示内容不允许超过2000字符！"
          });
          return
        }else{

          this.$post("/biz/hr/evaluate/psy/setting", {
            paperId: this.$route.query.row.paperId || localStorage.getItem('CPpaperId') || this.paperId,
            evaluateResult:  this.topForm.editorTopContent
          },3000,true,6).then((ret) => {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.goHerf()
          });
        }

      }
    },
    // 获取所有职位类别
    getHrPositionType(){
      this.$post("/hr/talent/hr-position-type/queryAll")
          .then(res => {
            this.options = res.data || [];
          })
          .catch(() => {
            return;
          });
    },
    getCheckedNodes(val){
      // console.log(JSON.stringify(val))
    },
    /* end */
    /* 返回 */
    goHerf() {
      let urlPath = ''
      if(this.$route?.query?.row?.ctype == 'yw'){
        urlPath = '/web/miniApp/evaluation/evaluationList'
      }
      if(this.$route?.query?.row?.ctype == 'jg'){
        urlPath = '/web/evaluationManagement/jgevaluationList'
      }
      this.$router.push({
        path: urlPath,
        query: {
          refresh: true,
        },
      });
    },
    // 页面顶部富文本
    editorInitTop() {
      editorTop = new E(this.$refs.editorTop);
      editorTop.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "table", // 表格
        "undo", // 撤销
        "redo" // 重复
      ];
      editorTop.config.zIndex = 100;
      editorTop.config.uploadImgShowBase64 = true;
      editorTop.config.uploadImgMaxSize = 1 * 1024 * 1024;
      editorTop.config.pasteFilterStyle = "text";
      editorTop.config.pasteIgnoreImg = false; //富文本编辑器中点击图片上传选择图片后激发的事件：
      let _this = this;
      editorTop.config.customUploadImg = function(files, insert) {
        //对上传的图片进行处理，图片上传的方式
        for (let i = 0; i < files.length; i++) {
          let formData = new FormData();
          formData.append("folder ", "COURSE_WARE");
          formData.append(
              "fileType ",
              files[i].name.substring(files[i].name.lastIndexOf(".") + 1)
          );
          formData.append("isPublic ", true);
          formData.append("file", files[i]);
          _this.$Postformat("/sys/upload", formData).then(result => {
            insert(result.data.fileURL);
          });
        }
      };
      editorTop.config.onchange = html => {
        this.topForm.editorTopContent = html;
      };
      editorTop.config.pasteTextHandle = function(content) {
        return content + "<p></p>";
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
      };
      editorTop.create();


      this.getEditorTopTxt()
      // const eWidth = this.$refs.editorTop.clientWidth;
      // this.eWidth = eWidth;
    },
    // 获取页面顶部富文本内容
    getEditorTopTxt(){
      this.$post("/biz/hr/evaluate/psy/getResultInfo", {
        paperId: this.$route.query.row.paperId || localStorage.getItem('CPpaperId') || this.paperId,
      },3000,true,6).then((ret) => {

        if(ret.data){
          editorTop.txt.html(ret.data);
          this.topForm.editorTopContent = ret.data
        }
      });
    },
    // // 新增编辑富文本
    // editorInit(componentDesc) {
    //   editor = new E(this.$refs.editor);
    //   editor.config.menus = [
    //     "head", // 标题
    //     "bold", // 粗体
    //     "fontSize", // 字号
    //     "italic", // 斜体
    //     "underline", // 下划线
    //     "strikeThrough", // 删除线
    //     "foreColor", // 文字颜色
    //     "backColor", // 背景颜色
    //     "list", // 列表
    //     "justify", // 对齐方式
    //     "quote", // 引用
    //     "emoticon", // 表情
    //     "image", // 插入图片
    //     "table", // 表格
    //     "undo", // 撤销
    //     "redo" // 重复
    //   ];
    //   editor.config.zIndex = 100;
    //   editor.config.uploadImgShowBase64 = true;
    //   editor.config.uploadImgMaxSize = 1 * 1024 * 1024;
    //   editor.config.pasteFilterStyle = "text";
    //   editor.config.pasteIgnoreImg = false; //富文本编辑器中点击图片上传选择图片后激发的事件：
    //   let _this = this;
    //   editor.config.customUploadImg = function(files, insert) {
    //     //对上传的图片进行处理，图片上传的方式
    //     for (let i = 0; i < files.length; i++) {
    //       let formData = new FormData();
    //       formData.append("folder ", "COURSE_WARE");
    //       formData.append(
    //           "fileType ",
    //           files[i].name.substring(files[i].name.lastIndexOf(".") + 1)
    //       );
    //       formData.append("isPublic ", true);
    //       formData.append("file", files[i]);
    //       _this.$Postformat("/sys/upload", formData).then(result => {
    //         insert(result.data.fileURL);
    //       });
    //     }
    //   };
    //   editor.config.onchange = html => {
    //     this.ruleForm.evaluationDetails = html;
    //   };
    //   editor.config.pasteTextHandle = function(content) {
    //     return content + "<p></p>";
    //     // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
    //   };
    //   editor.create();
    //
    //
    //   if(componentDesc){
    //     editor.txt.html(componentDesc);
    //     this.ruleForm.evaluationDetails = componentDesc
    //
    //   }
    //   // const eWidth = this.$refs.editor.clientWidth;
    //   // this.eWidth = eWidth;
    // },
    // 新增
    add() {
      this.centerDialogVisible = true;
      this.dialogTitle = "新增分类详情";
      // this.$nextTick(()=>{
      //   this.editorInit();
      // })
    },
    // 修改
    onEditor(row) {
      this.ruleForm.componentId = row.componentId
      this.ruleForm.componentName = row.componentName
      this.ruleForm.evaluationDetails = row.componentDesc

      this.centerDialogVisible = true;
      this.dialogTitle = "编辑分类详情";
      // this.$nextTick(()=>{
      //   this.editorInit(row.componentDesc);
      // })
    },
    doClose() {
      this.centerDialogVisible = false;
      this.$refs["ruleForm"].resetFields();
      this.ruleForm =  {
        componentId: "",
        componentName: "", // 分类名称
        evaluationDetails: '',// 分类详情
      }
      // editor.destroy()
    },
    //确认
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // let str = this.ruleForm.evaluationDetails.replace(/<[^>]+>/g, "");
          // str.replace(/\s*/g, "");
          // console.log(str);
          // if (!str) {
          //   this.$message({
          //     type: "warning",
          //     message: "请填写分类详情！"
          //   });
          //   return false;
          // }else{
          this.doAjax();
          // }
        }
      });
    },
    doAjax() {
      let url = "/biz/hr/evaluate/psy/addComponent";
      let params = {
        paperId: this.$route.query.row.paperId || localStorage.getItem('CPpaperId') || this.paperId,
        componentName: this.ruleForm.componentName,
      };
      if (this.ruleForm.componentId) {
        params.componentId = this.ruleForm.componentId;
        url = "/biz/hr/evaluate/psy/modifyComponent";
      }
      if(this.ruleForm.evaluationDetails){
        params.componentDesc = this.ruleForm.evaluationDetails;
      }


      this.$post(url, params,3000,true,6)
          .then((ret) => {
            if (ret.status == "0") {
              this.$message({
                message: ret.message,
                type: "success",
              });
              this.doClose();
              this.getData();
            }
          })
          .catch((err) => {
            return;
          });
    },


    // 删除
    onDelete(row) {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      })
          .then(() => {
            this.$post("/biz/hr/evaluate/psy/deleteComponent", {
              componentId: row.componentId,
            },3000,true,6).then((ret) => {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getData(-1);
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    //获取数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        paperId: this.$route.query.row.paperId || localStorage.getItem('CPpaperId') || this.paperId,
      };
      // if(this.saleType) {
      //   params.saleType = this.saleType
      // }
      this.doFetch({
        url: "/biz/hr/evaluate/psy/getComponentList",
        params,
        pageNum,
      },true,6);
    },



    // 点击查看/选择题目
    selectSubject(row){
      this.dialogVisible = true
      this.currentComponentId = row.componentId
      this.$post("/biz/hr/evaluate/psy/chooseQuestionList", {
        componentId: row.componentId
      },3000,true,6).then((ret) => {
        if(ret.status == '0'){
          this.tableData2 = ret.data
          this.$nextTick(()=>{
            this.multipleSelection = []
            this.$refs.multipleTable.clearSelection()
            if(this.tableData2.filter(e=>e.checked === true).length>0){
              this.multipleSelection = this.tableData2.filter(e=>e.checked === true)
              this.tableData2.filter(e=>e.checked === true).forEach((e,i)=>{
                this.$refs.multipleTable.toggleRowSelection(e,true)
              })
            }
          })

        }
      });
    },
    // 关闭查看/选择题目
    doCloseSubject(){
      this.tableData2 = []
      this.$refs.multipleTable.clearSelection()
      this.multipleSelection = []
      this.currentComponentId = ''
      this.dialogVisible = false
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection)
    },
    // 选择题目
    submitSubjectForm(){
      let questionIds = []
      if(this.multipleSelection.length < 1){
        this.$message.error("请至少选择一个题目!");
        return false
      }else{
        this.multipleSelection.forEach((e,i)=>{
          questionIds.push(e.questionId)
        })
      }
      this.$post("/biz/hr/evaluate/psy/chooseQuestion", {
        componentId: this.currentComponentId,
        questionIds: questionIds

      },3000,true,6).then((ret) => {
        if(ret.status == '0'){
          this.$message({
            type: "success",
            message: ret.message,
          });
          this.doCloseSubject()
          this.getData(-1);
        }
      });
    },


    // 设置分值
    setScore(row){
      this.scoreDialogVisible = true
      this.currentComponentId = row.componentId
      this.$post("/biz/hr/evaluate/psy/selectSourceList", {
        componentId: row.componentId
      },3000,true,6).then((ret) => {
        if(ret.status == '0'){
          if(ret.data.length > 0){
            // console.log(ret.data)
            //如果有数据进行回显
            ret.data.forEach((e,i)=>{
              if(e.typeList&&e.typeList.length > 0){
                let typeList = []
                e.typeList.forEach((eInner,iInner)=>{
                  typeList.push(eInner.positionTypeIdPath.split(','))
                })
                e.matchingPosition = typeList
              }else{
                e.matchingPosition = []
              }

            })
            this.scoreForms = ret.data
            // console.log(this.scoreForms)
          }else{
            //如果没有数据进行初始化
            this.scoreForms = [
              {
                minScore: undefined,
                maxScore: undefined,
                evaluation: '',
                proposal: '',
                matchingPosition: [],
              }
            ]
          }
        }
      });


    },
    // 关闭设置分值
    doCloseScore(){
      this.scoreForms = [
        {
          minScore: undefined,
          maxScore: undefined,
          evaluation: '',
          proposal: '',
          matchingPosition: [],
        }
      ]
      this.currentComponentId = ''
      this.scoreDialogVisible = false
    },
    // 设置分值提交
    submitScoreForm(){
      let hasFalse = false //提交是否存在错误
      try{
        this.scoreForms.forEach((e,i)=>{
          if(!e.minScore||!e.maxScore){
            this.$message.error('请补全第'+(i+1)+'组的分值区间！')
            hasFalse = true
            throw new Error() //终止循环
          }
          if(e.minScore>=e.maxScore){
            this.$message.error('分值区间最小值需小于分值区间最大值，请修改第'+(i+1)+'组的分值区间！')
            hasFalse = true
            throw new Error() //终止循环
          }
          if(!e.evaluation){
            this.$message.error('请填写第'+(i+1)+'组的分值内容！')
            hasFalse = true
            throw new Error() //终止循环
          }
          if(!e.proposal){
            this.$message.error('请填写第'+(i+1)+'组的提升建议！')
            hasFalse = true
            throw new Error() //终止循环
          }
          if(this.isScoreIntervalOverlap()){
            this.$message.error('分值区间不可重叠！')
            hasFalse = true
            throw new Error() //终止循环
          }

        })
      } catch {}

      // console.log('before',this.scoreForms,hasFalse)
      if(!hasFalse){
        this.scoreForms.forEach((e,i)=>{
          if(e.matchingPosition.length>0){
            let matchingPosition = []
            e.matchingPosition.forEach((eInner,iInner)=>{
              matchingPosition.push(eInner[eInner.length -1])
            })
            e.matchingPosition = matchingPosition.toString()
          }else{
            e.matchingPosition = null
          }
        })
        console.log(this.scoreForms)
        this.$post("/biz/hr/evaluate/psy/addSource", {
          componentId: this.currentComponentId,
          scoreList: this.scoreForms

        },3000,true,6).then((ret) => {
          if(ret.status == '0'){
            this.$message({
              type: "success",
              message: ret.message,
            });
            this.doCloseScore()
            this.getData(-1);
          }
        });
      }
    },
    // 设置分值添加
    scoreAdd(){
      this.scoreForms.push(
          {
            minScore: undefined,
            maxScore: undefined,
            evaluation: '',
            proposal: '',
            matchingPosition: [],
          }
      )
    },
    // 设置分值删除
    scoreDel(index){
      this.scoreForms.splice(index,1)
    },
    // 判断分数区间是否重叠
    isScoreIntervalOverlap(){
      let _this = this

      let isOverlap = false //是否重叠

      if (_this.scoreForms.length > 1) {
        _this.scoreForms.forEach(item => {
          _this.scoreForms.forEach(subItem => {
            if (item.minScore !== subItem.minScore || item.maxScore !== subItem.maxScore) {
              // 如果开始和结束 有一个不相等
              if ((subItem.minScore <= item.minScore && item.minScore <= subItem.maxScore) || (subItem.minScore <= item.maxScore && item.maxScore <= subItem.maxScore)) {
                // 分别把开始和结束，放入区间内比较
                isOverlap = true
                // this.$message.error('存在重叠！')
              }
            }
          })
        })
      }
      return isOverlap
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped>
/deep/.demo-ruleForm .el-table th.el-table__cell{
  padding: 0;
}
/deep/.el-table__empty-block{
  min-height: 300px;
}
.labelTitle{
  line-height: 32px;
  .redColor{
    color: red;
  }
}
/deep/.el-cascader-panel:first-child .el-checkbox{
  display:none!important;
}
.showtitle {
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
  position: relative;
  top: 100%;
  left: 0;
}
</style>
